import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function ExpandableList(props) {
    const classes = makeStyles((theme) => ({
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        background: "transparent",
        margin: 0,
        "&:before": {
            display: "none",
        },
        "&:not(:last-child)": {
            borderBottom: 0,
        },
    },
    }))();
    return (
        <Accordion className={classes.accordion} elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{props.children}</Typography>
            </AccordionDetails>
        </Accordion>
    );
}
