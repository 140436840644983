import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import { ContactModal } from "../components/contactModal";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import Layout from "../components/layout";
import ExpandableList from "../components/ExpandableList";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FunctionsIcon from '@material-ui/icons/Functions';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "2rem",
    },
    paragraph: {
        margin: "1rem auto",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
        },
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "80%",
        margin: "auto",
    },
    button: {
        margin: "0 1rem 1rem 0",
        fontWeight: "bold",
    },
}));

export default function Buy() {
    const classes = useStyles();
    return (
        <Layout>
            <Grid container alignItems="center" className={classes.container}>
                <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    className={classes.imageContainer}
                >
                    <img
                        src="/buy_house.svg"
                        alt="buy_house"
                        className={classes.image}
                    />
                </Grid>
                <Grid container item sm={12} md={6}>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h3">
                            Finding Your Dream Home
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">
                            The Nuances of Purchasing
                        </Typography>
                        <Typography>
                            Buying a home can be challenging whether or not you
                            are a first-timer. After all, there are so many
                            steps, tasks, and requirements, and you may be
                            anxious about making an expensive mistake. But
                            first-time homebuyers do enjoy some special
                            advantages created to encourage new entrants into
                            the real estate market. There are several things to
                            consider such as:
                        </Typography>
                        <ExpandableList title="&#8226; Your Financial Health">
                            You need to be prepared for both the purchase and
                            the ongoing expenses of a home. According to Canada
                            Mortgage and Housing Corporation (CMHC), your
                            monthly housing costs should not be more than about
                            35% of your gross monthly income. This includes
                            costs such as mortgage payments and utilities. Your
                            entire monthly debt load should not be more than 42%
                            of your gross monthly income. This includes your
                            mortgage payments and all your other debts.
                        </ExpandableList>
                        <ExpandableList title="&#8226; The type of home for you">
                            You have a number of options when purchasing a
                            residential property: a traditional single-family
                            home, a duplex, a townhouse, a condominium, a
                            co-operative, or a multifamily building with two to
                            four units. Each option has its pros and cons,
                            depending on your homeownership goals, so you need
                            to decide which type of property will help you reach
                            those goals.
                        </ExpandableList>
                        <ExpandableList title="&#8226; Securing finance options">
                            Most people need to borrow money to buy a home. You
                            also need to put some of your own money into the
                            purchase. A mortgage is likely the biggest loan you
                            get in your lifetime. Mortgages have different
                            features to meet different needs. It’s important
                            that you understand the options and features. The
                            Government of Canada also offers two tax credits for
                            specific types of homebuyers.
                        </ExpandableList>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">How I can Help</Typography>
                        <Typography>
                            A savvy realtor who works for you can protect your
                            interests and guide you through the buying process -
                            from negotiating a price to navigating home
                            inspections. This is where I come in. My domain
                            expertise will take away all your stress and
                            worries. I will answer questions and address all
                            your concerns. This includes utilizing all the
                            benefits that you are entitled to and securing the
                            best mortgage rates. I will help you find the house
                            of your dreams while ensuring that you make a
                            financially correct decision.
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.paragraph}>
                        <Grid>
                            <Button
                                component={Link}
                                to="/listings"
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<HomeWorkIcon />}
                            >
                                View Listings
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                component={Link}
                                to="/estimators"
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<FunctionsIcon />}
                            >
                                Mortgage Calculator
                            </Button>
                        </Grid>
                        <ContactModal></ContactModal>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
}
